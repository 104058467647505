<template>
  <div>
    <ControlNavbar/>

    <div class="container-fluid" v-if="start">
      <br/>
      <h5>
        {{ locale.total }}
        {{ (totalTopup || 0) | numeral($session.get("currencyFormat")) }}
        {{ $session.get("currency") }}
      </h5>
      <a-calendar @panelChange="dateChange">
        <ul slot="dateCellRender" slot-scope="value" class="events">
          <li v-for="item in getListData(value)" :key="item.content">
            <a-badge :status="item.type" :text="item.content"/>
          </li>
        </ul>
        <template slot="monthCellRender" slot-scope="value">
          <div v-if="getMonthData(value)" class="notes-month">
            <section>{{ getMonthData(value) }}</section>
            <span></span>
          </div>
        </template>
      </a-calendar>

      <br/><br/><br/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import firebase from "firebase";
// import Slick from "vue-slick";
import ControlNavbar from "@/components/ControlNavbar";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Calendar",
  components: {ControlNavbar},
  data() {
    return {
      locale: null,
      start: false,
      id: null,
      calendarData: null,
      totalTopup: null,
      historyMonth: null,
      yearvalues: {},
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            if (this.checkApiRes(result, {
              sessionId: this.$session.get("sessionId"),
            }, "account/session/deactivate", "post")) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
          });
    },
    dateChange(date, mode) {
      console.log("dateChange ", date, mode);
      let values = {};
      values.year = date.year();
      values.month = date.month();
      this.getAxios()
          .post(
              `${this.$session.get("baseURI")}transaction/topups/${this.id}`,
              values
          )
          .then((result) => {
            if (this.checkApiRes(result, values, `transaction/topups/${this.id}`, "post")) {
              // ok
              console.log("changed Month: ", result);
              this.totalTopup = result.data.data.topupTotal;
              this.historyMonth = result.data.data.histories;
              this.start = true;
            }
          });
    },
    getListData(value) {
      var numeral = require("numeral");
      let listData = [];
      // console.log(value._d, value.date());
      let values = {};
      values.year = value.year();
      values.month = value.month();
      // this.getAxios()
      //   .post(
      //     `${this.$session.get("baseURI")}transaction/topups/${this.id}`,
      //     values
      //   )
      //   .then((result) => {
      //     console.log(result);
      //   });
      // { type: "error", content: "This is error event 4." },
      //
      if (this.historyMonth)
        this.historyMonth.forEach((top) => {
          if (top.day == value.date()) {
            listData.push({
              type: "error",
              content: `${
                  top.credit |
                  numeral(this.$session.get("currencyFormat").replace("#", "0"))
              } ${this.$session.get("currency")}`,
            });
          }
        });

      return listData || [];
    },
    getMonthData(value) {
      console.log("month data", value.year(), value.month());
      let values = {};
      values.year = value.year();
      values.month = value.month();
      this.getAxios()
          .post(
              `${this.$session.get("baseURI")}transaction/topups/${this.id}`,
              values
          )
          .then((result) => {
            if (this.checkApiRes(result, values, `transaction/topups/${this.id}`, "post")) {
              // ok
              console.log(`mont is ${result.data.data.topupTotal}`);
              this.yearvalues[`${value.month()}${value.year()}`] =
                  result.data.data.topupTotal;
              return this.yearvalues[`${value.month()}${value.year()}`];
            }
          });
    },
    getData(month, year) {
      let values = {};
      values.year = year;
      values.month = month;
      this.getAxios()
          .post(
              `${this.$session.get("baseURI")}transaction/topups/${this.id}`,
              values
          )
          .then((result) => {
            if (this.checkApiRes(result, values, `transaction/topups/${this.id}`, "post")) {
              // ok
              console.log("changed Month: ", result);
              this.totalTopup = result.data.data.topupTotal;
              this.historyMonth = result.data.data.histories;
              this.start = true;
            }
          });
    },
  },
  created: function () {
    this.checkLocal();
    var today = new Date();
    this.start = false;
    console.log("data: ", this.$route.params.id || this.$session.get("backup"));
    if (!this.$route.params.id) {
      // undefined
      this.id = this.$session.get("backup");
      this.getData(today.getMonth() + 1, today.getFullYear());
      this.start = true;
    } else {
      this.id = this.$route.params.id;
      this.$session.set("backup", this.$route.params.id);
      this.getData(today.getMonth() + 1, today.getFullYear());
      this.start = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}
</style>
